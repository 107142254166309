import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ReactHtmlParser from "react-html-parser";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Vision = ({ location }) => {
    return (
      <StaticQuery
        query={graphql`
        query {
          wpgraphql {
            page(id: "/our-vision/", idType: URI) {
              content
              title
              slug
              uri
            }
          }
        }
        `}
        render={data => 
            <Layout location={location}>
               <SEO
                  keywords={[`virtualmoon`, `vr`, `moon`, `space`]}
                  title="Our Vision"
                />
              <section id="vision" className="text-left pt-24 sm:ml-5 md:pl-20 md:ml-32">
              <h1 className="text-white font-title font-thin text-4xl mb-5">{data.wpgraphql.page.title}</h1>
              <div className="text-white font-body sm:w-full md:w-5/6">{ ReactHtmlParser(data.wpgraphql.page.content) }</div>;
              <div>
            </div>
            </section>
            </Layout>
          }
      />
    )
}

Vision.propTypes = {
  location: PropTypes.object
};

export default Vision;
